<script>
import OnDemandForm from "@/views/menu/onDemand/onDemandForm";
import Component, { mixins } from "vue-class-component";
import { toastService } from "@/services/toastService.js";

@Component({
  inject: {
    onDemandService: "onDemandService",
    fileUploadService: "fileUploadService",
  }
})
export default class OnDemandEdit extends mixins(OnDemandForm) {
  formId = "new-onDemand-form";

  get fieldColumns() {
    return 2;
  }
  get actions() {
    return [
      {
        id: "save",
        type: "submit",
        text: this.translations.labels.button_save,
        leftIcon: "mdi-content-save-outline",
        disabled: () => {
          return this.fileUploadService.isUploading();
        },
        handle: this.save,
      },
    ];
  }
  async save(action, passesValidation) {
    this.errors = [];
    if (!passesValidation) {
      return;
    }
    try {
        await this.onDemandService.update(this.model.id, {
        idOnDemand: this.model.id,
        idEvent: this.model.idEvent,
        code: this.model.code,
        localizations: this.model.localizations,
      });
      this.$router.push("/onDemand/list");
      toastService.success(this.translations.success.onDemand_update);
    } catch (error) {
      this.errors = this.formatApiErrors(error);
    }
  }

  getFormTitle() {
    return this.translations.pageTitles.onDemands_edit;
  }

  async afterCreate () {
    this.model = await this.onDemandService.read(this.$route.params.id);
    this.isReady = true;
  }
}
</script>
